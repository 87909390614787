import "./App.css";
import Nabvar from "./Components/Nabvar";
import Hero from "./Components/Hero";
import Team from "./Components/Team";
import Footer from "./Components/Footer";
import Events from "./Components/Events";
import { EVENTS } from "./Constants";
import About from "./Components/About";

function App() {
  return (
    <div>
      <Nabvar />
      <Hero />
      <div className="mt-20">
        <About />
      </div>
      <div className="mt-20">
        <Events events={EVENTS} />
      </div>
      <div className="mt-20">
        <Team />
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
}

export default App;
