import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div
      id="about"
      className="bg-white shadow-md rounded-lg p-5 max-w-screen-xl mx-auto"
    >
      <div className="flex flex-col-reverse sm:flex-row items-center">
        <div className="w-full sm:w-1/2">
          <motion.div
            initial={{ opacity: 15, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 3 }}
          >
            <div className="text flex flex-col items-center justify-center">
              <span className="card-title raleway text-2xl text-gray-900 font-bold underline underline-offset-3 decoration-8 decoration-[#830029] dark:decoration-blue-600 mt-5">
                About us
              </span>
              <p className="hammersmith-one-regular  text-gray-700  mt-5">
                The Asper Professional Graduate Student Association (APGSA)
                represents the body of graduate students enrolled in the MBA,
                MFin, and MSCM programs at the Asper School of Business,
                University of Manitoba. The mission of the APGSA is to enhance
                the graduate student experience at the Asper School by creating
                opportunities for students to form lasting relationships with
                their colleagues and the wider Manitoba business community. The
                APGSA is responsible for representing the student body to the
                MBA, MFin, MSCM programs, faculties, and Graduate Students’
                Association; staying connected to related groups such as
                Alumnus, Young Associates, and Associates; overseeing the
                Student Engagement Representative Program, and organizing
                student events throughout the academic year. These events allow
                students to grow their network and get to know their fellow
                classmates.
              </p>
            </div>
          </motion.div>
        </div>
        <div className="w-full sm:w-1/2">
          <motion.div
            initial={{ opacity: 15, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 3 }}
          >
            <div className="image object-center ml-5 text-center">
              <img src="/home.jpg" alt="home" loading="lazy" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
