import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [colorChange, setColorChange] = useState(window.scrollY >= 80);
  const [activeSection, setActiveSection] = useState();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const navbarClasses = `navbar fixed top-0 left-0 right-0 z-10 shadow-sm ${
    colorChange ? "bg-white" : ""
  }`;

  return (
    <div className={navbarClasses}>
      {/* Hamburger menu dropdown for small screens */}
      <div className="dropdown lg:hidden">
        <div tabIndex={0} role="button" className="btn btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        </div>
        <ul
          tabIndex={0}
          className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 font-medium hammersmith-one-regular rounded-box w-52"
        >
          <li>
            <Link
              to="about"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "about" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="events"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "events" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              to="team"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "team" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              Team
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex-1">
        <Link
          to="home"
          smooth
          className={` btn btn-ghost text-3xl hammersmith-one-regular  ${
            activeSection === "home" ? "text-black" : ""
          } ${colorChange ? "text-[#830029]" : "text-black"} `}
          spy={true}
          activeClass="active"
          onSetActive={handleSetActive}
        >
          APGSA
        </Link>
      </div>
      <div className="hidden lg:flex flex-1 navbar-end">
        <ul className="menu menu-horizontal  px-1 font-medium hammersmith-one-regular">
          <li>
            <Link
              to="about"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "about" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="events"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "events" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              to="team"
              smooth
              className={`${colorChange ? "text-[#830029]" : "text-black"} ${
                activeSection === "team" ? "active-section" : ""
              } sm:text-xl`}
              spy={true}
              activeClass="active"
              onSetActive={handleSetActive}
            >
              Team
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
