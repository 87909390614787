const social = "/social.png";
const boardroom = "/boardroom.png";
const games = "/games.png";
const Case = "/case.png";
const elections = "/election.png";

const EVENTS = [
  {
    title: "Social Events",
    icon: social,
    description:
      "APGSA hosts a variety of social events throughout the year, providing students with opportunities to meet fellow students, alumni, and professionals from the business community, facilitating valuable networking opportunities.",
  },
  {
    title: "Boardroom Series",
    icon: boardroom,
    description:
      "In collaboration with the Asper CDC, APGSA hosts a boardroom series that offers a select group of students the chance to visit organizations, engage with senior business leaders, ask insightful questions, gain industry insights, and explore potential career paths.",
  },
  {
    title: "MBA Games",
    icon: games,
    description:
      "APGSA annually manages the recruitment and progression of the MBA Games, the largest collegiate competition in Canada, open to MFin, MSCM, and MBA students. Stay updated on their journey by following their Instagram.",
  },
  {
    title: "Case Competitions",
    icon: Case,
    description:
      "APGSA collaborates with the case competition team at Asper to advertise extracurricular case competition opportunities to graduate students, where participants form teams, analyze business problems, and present their solutions to judges for evaluation and feedback. These are often external opportunities.",
  },
  {
    title: "APGSA Elections",
    icon: elections,
    description:
      " All students are eligible to run for executive roles within APGSA, with elections occurring in September and the new committee assuming their positions in October each year.",
  },
];

/*
const nameOfperson="/photoname.format"
{
  title:"give title here",
  description:"Write the description",
  year:"give the year",
  image:nameOfperson
}
*/

const shivam = "/Shivam.webp";
const nishtha = "/Nishtha.webp";
const Kathy = "/Kathy.webp";
const helena = "/Helena.webp";
const sebastine = "/Sebastine.webp";
const chidinma = "/Chidinma.webp";
const pan = "/Pan.webp";
const laura = "/Laura.webp";
const arifin = "/Arifin.webp";
const kalyn = "/Kalyn.webp";
const sheena = "/Sheena.webp";
const pinaz = "/Pinaz.webp";
const stephane = "/Stephane.webp";
const chitranjan = "/Chitranjan.webp";
const divya = "/Divya.webp";
const jessica = "/Jessica.webp";
const tony = "/Tony.png";

const TEAMS = [
  {
    name: "Shivam Bhatia",
    description: "President",
    year: "2023",
    image: shivam,
  },
  {
    name: "Helena Goldman",
    description: "Vice-President, MBA",
    year: "2023",
    image: helena,
  },
  {
    name: "Sebastine Omeje",
    description: "Vice-President, MFin",
    year: "2023",
    image: sebastine,
  },
  {
    name: "Chidinman Onyedum",
    description: "Vice-President, MSCM",
    year: "2023",
    image: chidinma,
  },
  {
    name: "Xiaolong Pan",
    description: "Director of Events",
    year: "2023",
    image: pan,
  },
  {
    name: "Kathy Cao",
    description: "Director of Finance",
    year: "2023",
    image: Kathy,
  },
  {
    name: "Nishtha Varma",
    description: "Director of Marketing",
    year: "2023",
    image: nishtha,
  },
  {
    name: "Laura Effinger",
    description: "PGP Committee Representative",
    year: "2023",
    image: laura,
  },
  {
    name: "Arifin Zaman",
    description: "President",
    year: "2022",
    image: arifin,
  },
  {
    name: "Tony Wu",
    description: "Vice-President, MSCM",
    year: "2022",
    image: tony,
  },
  {
    name: "Kalyn Maskiw-Connelly",
    description: "Vice-President, MBA",
    year: "2022",
    image: kalyn,
  },
  {
    name: "Sheena Gee",
    description: "Vice-President, MFin",
    year: "2022",
    image: sheena,
  },

  {
    name: "Pinaz Mehta",
    description: "Director of Marketing, Interim President",
    year: "2022",
    image: pinaz,
  },
  {
    name: "Stephane Lacroix",
    description: "Director of Finance",
    year: "2022",
    image: stephane,
  },
  {
    name: "Chitranjan Singh",
    description: "Director of Events",
    year: "2022",
    image: chitranjan,
  },
  {
    name: "Shivam Bhatia",
    description: "Interim Director of Marketing",
    year: "2022",
    image: shivam,
  },
  {
    name: "Divya Yennam",
    description: "Interim Director of Finance",
    year: "2022",
    image: divya,
  },
  {
    name: "Jessica Burtnick",
    description: "PGP Committee Representative",
    year: "2022",
    image: jessica,
  },
];

export { EVENTS, TEAMS };
