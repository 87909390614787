import React from "react";

const Hero = () => {
  return (
    <div
      id="home"
      className="hero min-h-screen z-1 bg-gray-900 relative"
      style={{
        backdropFilter: "blur(8px)",
      }}
    >
      <div className="bg-image absolute top-0 left-0 w-full h-full">
        <img
          src="https://umanitoba.ca/asper/sites/asper/files/styles/3x2_900w/public/2023-06/Drake%20exterior%20-%20Asper%20School.png?itok=plQQ5wen"
          alt="cover"
          className="object-cover w-full h-full"
          style={{ filter: "blur(5px)" }}
          loading="lazy"
        />
      </div>
      <div className="hero-content p-3 flex-col lg:flex-row-reverse items-center">
        <img
          src="/logo-white.png"
          alt="logo"
          className="max-w-sm rounded-lg  mb-8  lg:ml-20"
          loading="lazy"
        />
        <div className="lg:mr-20">
          <h1 className="text-5xl font-bold text-white text-stroke-white">
            WELCOME TO
            <br />
            <br />
            APGSA
          </h1>
          <h2 className="text-3xl font-bold text-white text-stroke-black">
            Asper Professional Graduate Students Association
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Hero;
