import React, { useState } from "react";
import { motion } from "framer-motion";
import { EVENTS } from "../Constants";

const Events = () => {
  const [currentEvent, setCurrentEvent] = useState(EVENTS[0]);

  const handlePrevClick = () => {
    const newIndex =
      (EVENTS.length + EVENTS.indexOf(currentEvent) - 1) % EVENTS.length;
    setCurrentEvent(EVENTS[newIndex]);
  };

  const handleNextClick = () => {
    const newIndex = (EVENTS.indexOf(currentEvent) + 1) % EVENTS.length;
    setCurrentEvent(EVENTS[newIndex]);
  };

  return (
    <div
      id="events"
      className="px-2 py-10 bg-[#830029] text-center flex justify-center"
    >
      <div className="max-w-screen-xl flex flex-col sm:flex-row items-center">
        <div className="w-full sm:w-1/2 order-2 sm:order-1">
          <motion.div
            initial={{ x: -500, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <div className="image object-center mx-auto  text-center">
              <motion.img
                key={currentEvent.icon}
                src={currentEvent.icon}
                alt={currentEvent.title}
                style={{
                  height: "330px",
                  width: "500px",
                  background: "cover",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 10)",
                }}
                initial={{ x: -500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              />
            </div>
          </motion.div>
        </div>
        <div className="w-full sm:w-1/2 order-1 sm:order-2">
          <motion.div
            initial={{ x: 500, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <div className="text flex flex-col items-center justify-center">
              <span className="card-title raleway text-2xl text-white font-bold underline underline-offset-3 decoration-8 decoration-white dark:decoration-blue-600 mt-5">
                Our Events
              </span>
              <div className="mx-auto relative">
                <ul className="mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 relative mb-6">
                  <motion.li
                    key={currentEvent.title}
                    className="rounded-xl bg-white px-6 py-8 shadow-sm w-full md:w-auto mx-auto"
                    initial={{ x: 500, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    <h3 className="raleway text-4xl my-3 font-display font-medium">
                      {currentEvent.title}
                    </h3>
                    <p className="hammersmith-one-regular text-gray-700 mt-3">
                      {currentEvent.description}
                    </p>
                  </motion.li>
                </ul>
                <button
                  className="absolute bottom-0 left-0 bg-gray-900 text-white px-4 py-2 rounded-md"
                  onClick={handlePrevClick}
                  disabled={EVENTS.indexOf(currentEvent) === 0}
                >
                  &lt;
                </button>
                <button
                  className="absolute bottom-0 right-0 bg-gray-900 text-white px-4 py-2 rounded-md"
                  onClick={handleNextClick}
                  disabled={EVENTS.indexOf(currentEvent) === EVENTS.length - 1}
                >
                  &gt;
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Events;
